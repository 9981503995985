<template>
  <div>
    <!-- Row Start -->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Remarks List</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="outline-primary" v-b-modal.add-remarks-modal
                >Add Remarks</b-button
              >
            </div>
          </div>

          <div class="card-body body-fluid">
            <b-table
              responsive
              striped
              hover
              :items="remarksstatuslist"
              :fields="fields"
            >
              <template #cell(actions)="row">
                <b-button @click="ShowEditModal(row.item.id)" variant="primary"
                  >Edit</b-button
                >
                <b-button
                  @click="deleteremarks(row.item.id)"
                  class="ml-2"
                  variant="danger"
                  >Delete</b-button
                >
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <!-- Row End -->
    <!-- Add Modal -->
    <b-modal
      id="add-remarks-modal"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addremarks"
      title="Add Remarks"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Name" label-for="name-input">
              <b-form-input v-model="form.name"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      id="edit-remarks-modal"
      ref="modal"
      @hidden="resetModal"
      @ok="updateremarks"
      title="Edit Remarks"
    >
      <form ref="editform" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Name">
              <b-form-input v-model="form.name"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- </form> -->
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      remarksstatuslist: [],
      errors: [],
      isLoading: false,
      fields: ["name", "actions"],
      form: {
        name: null,
        id: null,
      },
    };
  },
  components: {
    Loading,
  },
  created() {
    this.getRemarksStatusList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Settings" },
      { title: "Remarks List" },
    ]);
  },
  methods: {
    checkErrors() {
      if (this.form.name) {
        return true;
      }

      this.errors = [];

      if (!this.form.name) {
        this.errors.push("Name is required.");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    resetModal() {
      this.form.name = null;
    },
    addremarks(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.post("remarksstatuslist", this.form)
          .then(() => {
            this.getRemarksStatusList();
            this.$nextTick(() => {
              this.$bvModal.hide("add-remarks-modal");
              this.makeToastVariant(
                "success",
                "Remarks added successfully",
                "Success"
              );
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    updateremarks(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.update("remarksstatuslist", this.form.id, this.form)
          .then(() => {
            this.getRemarksStatusList();
            this.$nextTick(() => {
              this.$bvModal.hide("edit-remarks-modal");
              this.makeToastVariant(
                "info",
                "Remarks updated successfully",
                "Success"
              );
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    getRemarksStatusList() {
      this.isLoading = true;
      ApiService.get("remarksstatuslist")
        .then(({ data }) => {
          
          this.remarksstatuslist = data;
          this.isLoading = false;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    ShowEditModal(id) {
      this.isLoading = true;
      ApiService.get("remarksstatuslist", id)
        .then(({ data }) => {
          this.form = data;
          this.isLoading = false;
          this.$bvModal.show("edit-remarks-modal");
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    deleteremarks(id) {
      this.isLoading = true;
      ApiService.delete("remarksstatuslist", id)
        .then(() => {
          this.getRemarksStatusList();
          this.makeToastVariant(
            "warning",
            "Remarks deleted successfully",
            "Success"
          );
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
  },
};
</script>
